@import url('https://fonts.googleapis.com/css2?family=Miriam+Libre:wght@400;700&display=swap');

.navheader {
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 150px;
  background-color: #e9e9e9df;
  box-shadow: 0 0 10px #000000;
  display: flex;
  align-items: center;
}
.companyFont {
  color: #a20;
  float: left;
  -webkit-text-stroke: 0.5px #000;

  font-weight: 2000;
}
.MainLayout {
  min-height: 100vh;
}
.footerbtm {
  color: grey;
  font-family: 'Miriam Libre', sans-serif;
}
button {
  font-family: 'Miriam Libre', sans-serif;
}
.logo {
  width: auto;
  height: 130px;
  margin-right: auto;
  order: 2;
}
.menuBtn {
  color: #a20000;
  border-color: #a20000;
  border: 2px solid;
  padding: 10px 10px 28px 10px;
}
.right-menu {
  background-color: transparent;
  order: 2;
  border-bottom: 0;
  font-size: large;
  font-weight: 700;
  color: #000000;
  justify-content: right;
}

.right-menu-mobile {
  background-color: transparent;
  order: 2;
  border-bottom: 0;
  font-size: large;
  font-weight: 700;
  color: #000000;
}
.rightMenuTxt:hover {
  color: #D8ABAB !important;
}
.ant-menu-item:hover::after,
.ant-menu-item-selected:after {
  border-bottom: 0px solid transparent !important;
}
.ant-menu-item-selected {
  color: #a20000 !important;
}
.quoteBtn {
  background-color: #a20000;
  border-radius: 40px !important;
  font-size: medium;
  font-family: 'Miriam Libre', sans-serif;
  font-weight: 900;
  color: white;
  width: 150px;
  text-align: center;
}

.quoteBtn:hover {
  background-color: #D8ABAB !important;
  color: black !important
}

.quoteBtn:active {
  background-color: #D8ABAB !important;
  color: black !important
}

.quoteBtn::selection {
  background-color: #D8ABAB !important;
  color: black !important
}

.space {
  height: 150px;
}
.container {
  text-align: center;
  color: white;
  width: 100%;
  position: relative;
  align-items: center;
  font-family: 'Miriam Libre', sans-serif;
}

.mainText {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  font-size: 10vh;
  font-weight: 600;
  font-family: 'Miriam Libre', sans-serif;
  text-shadow: #000 3px 0 10px;

}
.textDiv {
  /* display: flex;
 align-items: center; */
  margin: 0;
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
  justify-content: center;
  align-items: center;
  /* display: flex;
  height: 100%;
 justify-content: center;
  align-items: center; */
}

.filter {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.459);
}

.productFilter {
  
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.459);
  border-radius: 100%;
}

/* .productFilter:hover {

  background-color: rgba(0, 0, 0, 0);

} */

.subText {
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  font-size: 4vh;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Miriam Libre', sans-serif;
  width: 70%;
  color: rgb(216, 216, 216);
  text-shadow: #000 4px 0 10px;
}
.downBtn {
  font-size: 50px;
  opacity: 0;
  animation: bounce, fadeInAnimation;
  animation-delay: 1s;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s; 
  -webkit-animation-fill-mode: both; 
  animation-fill-mode: both; 
  position: absolute;
  bottom: 10px;
  margin-left: auto; 
  margin-right: auto; 
  left: 0; 
  right: 0; 
  width: 100%;
  color: rgb(216, 216, 216);
}
.downBtnIcon:hover {
  color: #a20000;
}
.secondMainText {
  padding-bottom: 20px;
  color: black;
  font-size: 3em;
  font-weight: 800;
  font-family: 'Miriam Libre', sans-serif;
}


.keyPointBtn {
  color: #000000;
  font-size: medium;
  font-weight: 600;
  border-radius: 5px;
  margin: 10px;
  border: 2px solid #a2000000;
  width: 100%;
  height: 100%;
  padding-top: 10px;
}

.keyPointBtnMbl {
  color: #000000;
  font-size: medium;
  font-weight: 600;
  border-radius: 5px;
  margin: 10px;
  border: 2px solid #7c757500;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  background-color: white;
  box-shadow: 0 0 5px #0000005e;
}


.KeyPointDescription {
  color: black;
}

.svgIcon {
  height: 6rem;
  width: auto;
}

.svgIconMbl {
  height: auto;
  width: 3rem;
}

.waBtn {
  position: fixed;
  bottom: 5%;
  right: 0;
  background-color: #72C058;
  align-items: center;
  justify-items: center;
  z-index: 1;
  color: white;
  border-radius: 30px 0px 0px 30px;
  height: 60px;
  font-weight: 600;
  box-shadow: 0 0 10px #00000085;
}

.waBtnLogo {
  width: 60px;
  height: auto;
}

.waBtnLogo:hover {
  animation: ckw 1.5s 1 linear;
  transform-origin: 50% 50%;
}

.productsContainer {
  display: flex;
  flex-wrap: no-wrap;
  width: 50vw;
  overflow-x: scroll;
  position: relative;
  
}

.ant-form-item-label label{
  font-size: 16px;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none !important;
}

.ant-picker-panel-container, .ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important; 
}
@keyframes ckw {
  0%, 20%, 40%, 60%, 80%, 100% {
      transform: rotate(-20deg);
  }
  10%, 30%, 50%, 70%, 90% {
      transform: rotate(30deg);
  }
}

@-webkit-keyframes bounce { 
  0% {-webkit-transform: translateY(20px);} 
  20% {-webkit-transform: translateY(-40px);} 
  40% {-webkit-transform: translateY(10px);} 
  60% {-webkit-transform: translateY(-30px);} 
  80%, 100% {-webkit-transform: translateY(0);} 
  90% {-webkit-transform: translateY(-7px);} 
} 

@keyframes bounce { 
  0% {transform: translateY(20px);} 
  20% {transform: translateY(-40px);}
  40% {transform: translateY(10px);} 
  60% {transform: translateY(-30px);} 
  80%, 100% {transform: translateY(0);} 
  90% {transform: translateY(-7px);}
}

.bounce { 
  -webkit-animation-name: bounce; 
  animation-name: bounce; 
}
.ant-menu-inline {
  width: 50px !important;
}
/* @media (max-width: 650px) {
  .quoteBtn {
    width : 50px;
  }
} */

@media (max-width: 1200px) {
  .productsContainer {
    width: 90%;
  }
}

@media (max-width: 1067px) {
  .logo {
    width: auto;
    height: 90px;
  }
  .navheader {
    height: 100px;
  }
  .space {
    height: 100px;
  }
  .mainText {
    margin-right: auto;
    margin-left: auto;
    font-size: 6vh;
    font-weight: 600;
  }
  .subText {
    font-size: 3vh;
    font-weight: 600;
  }
  .secondMainText {
    font-size: 5vw;
    font-weight: 600;
  }
  .right-menu {
    font-size: medium;
  }
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
